*{
    margin: 0;
    padding: 0;
}
.navbar {
    width: 103%;
    height: 80px;
    background-color: rgb(19, 19, 19);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* position: sticky; */
  }
  
  .links {
    margin-right: 50px;
    display: flex;
    align-items: center;
  }
  
  .links a {
    text-decoration: none;
    color: white;
    margin-left: 20px;
    font-size: 25px;
  }
  /* media query for mobile responsive */
  @media (max-width:768px) and (min-height:400px){
    .navbar{
      width: 100%;
    }
  }